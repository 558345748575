<template lang="">
    <div class="max-w-6xl w-full mx-auto">
        <p class="text-center text-2xl md:text-4xl">Craete Your Blog</p>

        <div class="mt-5">
            <status-message :message="message" :status-code="200" timeout="6000"></status-message>

            <form @submit.prevent="submitForm" method="post">

                <div class="mb-4 flex items-center justify-center max-w-sm mx-auto rounded-lg p-3">
                    <div id="wrapper" v-html="imagePreviewer"></div>
                </div>
                
                <div class="mb-4 flex items-center justify-center bg-gray-200 max-w-sm mx-auto rounded-lg p-3">
                    <input type="file" name="image" v-on:change="onFileChange" accept="image/png, image/gif, image/jpeg">
                </div>

                <label class="text-lg">Post Title:</label>
                <input-text name="title" label="Title" type="text" placeholder="Enter Your Title" size="sm" class="w-full"/>

                <label class="text-lg">Summary:</label>
                <textarea name="summary" label="Summary" type="text" placeholder="Write Your Summary ..." class="w-full rounded-md h-16 p-2"></textarea>

                <label class="text-lg">Body:</label>
                <textarea name="body" label="Body" type="text" placeholder="Write Your Body ..." class="w-full rounded-md h-48 p-2"></textarea>

                <div class="mt-3 mb-3 text-center w-full">
                    <btn variant="info">Save Your Post</btn>
                </div>

            </form>
        </div>
    </div>
</template>
<script>
import InputText from '../../../../components/input/input-text.vue';
import Btn from '../../../../components/btn.vue';
import statusMessage from '../../../../components/status-message.vue';

export default {
    name: 'create-blog',
    components: {
        InputText,
        Btn,
        statusMessage
    },
    data() {
        return{
            image: '',
            loading: false,
            formErrors: [],
            message: "",
            imagePreviewer: "",
        }
    },
    methods: {
        onFileChange(event) {
            // let files = e.target.files || e.dataTransfer.files;
            // if (!files.length)
            //     return;
            // this.createImage(files[0]);


            let image_file = event.target.files[0];
            let reader = new FileReader;

            reader.readAsDataURL(image_file);

            reader.onload = (event) => {
                let image_url = event.target.result;
                let image = document.createElement("img");

                image.src = image_url;

                image.onload = (e) => {
                    let canvas = document.createElement("canvas");
                    // let ratio = 1080 / e.target.width;
                    let ratio = 720 / e.target.width;

                    // canvas.width = 1080;
                    canvas.width = 720;
                    canvas.height = e.target.height * ratio;
                    const context = canvas.getContext("2d");

                    context.drawImage(image, 0, 0, canvas.width, canvas.height);
                    let new_image_url = context.canvas.toDataURL("image/png", 90);
                    let new_image = document.createElement("img");
                    new_image.src = new_image_url;

                    this.image = this.dataURItoBlob(new_image_url);

                    document.getElementById("wrapper").appendChild(new_image);
                }
            }
        },
        createImage(file) {
            let reader = new FileReader();
            let vm = this;
            reader.onload = (e) => {
                vm.image = e.target.result;
            };
            reader.readAsDataURL(file);
        },
        submitForm(e) {
            this.loading = true;
            this.formErrors = [];

            const formData = new FormData(e.target);

            console.log(this.image);

            formData.append("image", this.image);

            this.$store.dispatch('blogs/submitPost', {
                data: formData,
                has_errors: (errors) => this.formErrors = errors,
                success: (response) => {
                    // const data = response.data;
                    // this.message = response.data.message;
                    this.$router.push('/my/blog/profile');
                }
            })
            .finally(() => this.loading = false);
        },
        dataURItoBlob(dataURI) {
            // convert base64/URLEncoded data component to raw binary data held in a string
            var byteString;
            if (dataURI.split(',')[0].indexOf('base64') >= 0)
                byteString = atob(dataURI.split(',')[1]);
            else
                byteString = unescape(dataURI.split(',')[1]);

            // separate out the mime component
            var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

            // write the bytes of the string to a typed array
            var ia = new Uint8Array(byteString.length);
            for (var i = 0; i < byteString.length; i++) {
                ia[i] = byteString.charCodeAt(i);
            }

            return new Blob([ia], {type:mimeString});
        }
    }
}
</script>

<style lang="">
    
</style>